import Link from 'next/link';
import { PulseLoader } from 'react-spinners';
import css from '../styles/modules/Button.module.scss';

const Button = ({ type, style, href, color, loading, disabled, onClick, visibility, fullWidth, withShadow, inline, className, children }) => {
  const handleOnClick = (e) => {
    if (loading || disabled) {
      e.preventDefault();
      return;
    }

    if (onClick)
      onClick();
  };

  /** TEMPLATES */
  if (visibility === false)
    return null;

  if (type === 'link')
    return (
      <Link href={href} passHref>
        <a
          className={[
            css.bt,
            className,
            loading && css.loading,
            disabled && css.disabled,
            fullWidth && css.full,
            withShadow && css.shadow,
            inline && css.inline,
            color && css[color]
          ].filter(Boolean).join(' ')}
          role="button"
          tabIndex={0}
          style={style}
          onClick={handleOnClick}
        >
          <PulseLoader
            size={6}
            cssOverride={{ position: 'absolute' }}
            speedMultiplier={0.75}
            color="#FFFFFF"
            loading={loading}
          />
          <div className={css.content}>{children}</div>
        </a>
      </Link>
    );

  if (type === 'external')
    return (
      <a
        className={[
          css.bt,
          className,
          loading && css.loading,
          disabled && css.disabled,
          fullWidth && css.full,
          withShadow && css.shadow,
          inline && css.inline,
          color && css[color]
        ].filter(Boolean).join(' ')}
        role="button"
        tabIndex={0}
        style={style}
        href={href}
        target="_blank"
        onClick={handleOnClick}
      >
        <PulseLoader
          size={6}
          cssOverride={{ position: 'absolute' }}
          speedMultiplier={0.75}
          color="#FFFFFF"
          loading={loading}
        />
        <div className={css.content}>
          {children}
        </div>
      </a>
    );

  return (
    <button
      className={[
        css.bt,
        className,
        loading && css.loading,
        disabled && css.disabled,
        fullWidth && css.full,
        withShadow && css.shadow,
        inline && css.inline,
        color && css[color]
      ].filter(Boolean).join(' ')}
      type="button"
      style={style}
      onClick={handleOnClick}
    >
      <PulseLoader
        size={6}
        cssOverride={{ position: 'absolute' }}
        speedMultiplier={0.75}
        color="#FFFFFF"
        loading={loading}
      />
      <div className={css.content}>
        {children}
      </div>
    </button>
  );
};

Button.displayName = 'Button';
Button.defaultProps = {
  type: 'button',
  loading: false,
  disabled: false,
  visibility: true
};

export default Button;
