import Link from 'next/link';
import { withRouter } from 'next/router';
import { Fragment } from 'react';
import { useAppState } from '../context/app';
import { useObjectsDispatch } from '../context/objects';
import navigationData from '../data/navigation.json';

import Button from '../components/Button';
import Favorite from '../public/images/favorite.svg';

const HeaderNavigation = ({ router, region }) => {
  /** STATES */
  const appState = useAppState();
  const setObjectsState = useObjectsDispatch();

  if (region && region.region)
    region = region.region;

  if (!region || region == undefined || region == "")
    region = process.env.NEXT_PUBLIC_DEFAULT_REGION;

  /** TEMPLATE */
  return (
    <Fragment>
      <nav className="menu">
        {navigationData.map((value, index) => (
          <Link href={[value.region ? "/" + region : '', value.path].join('')} key={index} passHref>
            <a
              role="button"
              tabIndex={0}
              className={[
                'link',
                (router.pathname === value.path || value.alias.includes(router.pathname)) && 'active'
              ].join(' ')}
              title={value.text}
              target={value.newTab && '_blank'}
              onClick={() => {
                if (value.path === '/objects')
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {}
                  });
              }}
            >
              {value.text}
              {value.label && <span className={value.labelColor}>{value.label}</span>}
            </a>
          </Link>
        ))}
      </nav>
      <div className="addBtn">
        <Button
          color="red"
          type="link"
          href={['/', region, '/add'].join('')}
        >
          <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.563 4.35001C23.4604 2.26453 20.7874 0.84812 17.8811 0.279299C14.9748 -0.289523 11.9651 0.014694 9.23135 1.15361C6.49762 2.29253 4.16221 4.21517 2.51942 6.67924C0.876634 9.14331 0 12.0385 0 15C0 17.9615 0.876634 20.8567 2.51942 23.3208C4.16221 25.7848 6.49762 27.7075 9.23135 28.8464C11.9651 29.9853 14.9748 30.2895 17.8811 29.7207C20.7874 29.1519 23.4604 27.7355 25.563 25.65C26.9689 24.2556 28.0849 22.5965 28.8464 20.7686C29.6079 18.9408 30 16.9802 30 15C30 13.0198 29.6079 11.0592 28.8464 9.23135C28.0849 7.40347 26.9689 5.74445 25.563 4.35001ZM6.37802 23.535C4.71391 21.8512 3.58501 19.7128 3.1334 17.3889C2.68178 15.065 2.92764 12.6595 3.84001 10.475C4.75239 8.29048 6.29052 6.42466 8.26085 5.11232C10.2312 3.79997 12.5456 3.09974 14.913 3.09974C17.2804 3.09974 19.5949 3.79997 21.5652 5.11232C23.5355 6.42466 25.0737 8.29048 25.986 10.475C26.8984 12.6595 27.1443 15.065 26.6926 17.3889C26.241 19.7128 25.1121 21.8512 23.448 23.535C21.1838 25.7975 18.1139 27.0684 14.913 27.0684C11.7122 27.0684 8.64225 25.7975 6.37802 23.535Z" fill="white" />
            <path d="M21.7392 13.5003H16.4142V8.17529C16.4142 7.77747 16.2562 7.39594 15.9749 7.11463C15.6936 6.83333 15.312 6.67529 14.9142 6.67529C14.5164 6.67529 14.1349 6.83333 13.8536 7.11463C13.5723 7.39594 13.4142 7.77747 13.4142 8.17529V13.5003H8.07422C7.67639 13.5003 7.29486 13.6583 7.01356 13.9396C6.73225 14.2209 6.57422 14.6025 6.57422 15.0003C6.57422 15.3981 6.73225 15.7796 7.01356 16.0609C7.29486 16.3423 7.67639 16.5003 8.07422 16.5003H13.4142V21.8253C13.4142 22.2231 13.5723 22.6046 13.8536 22.8859C14.1349 23.1673 14.5164 23.3253 14.9142 23.3253C15.312 23.3253 15.6936 23.1673 15.9749 22.8859C16.2562 22.6046 16.4142 22.2231 16.4142 21.8253V16.5003H21.7392C22.137 16.5003 22.5186 16.3423 22.7999 16.0609C23.0812 15.7796 23.2392 15.3981 23.2392 15.0003C23.2392 14.6025 23.0812 14.2209 22.7999 13.9396C22.5186 13.6583 22.137 13.5003 21.7392 13.5003Z" fill="white" />
          </svg>
          <span style={{ marginLeft: 10 }}>
            Добавить объявление
          </span>
        </Button>
      </div>
      <Link href="/favorites" passHref>
        <a className="liked" title="Избранное" aria-label="Избранное">
          {appState.favorites.length > 0 && <span>{appState.favorites.length}</span>}
          <Favorite />
        </a>
      </Link>
    </Fragment>
  );
};

export default withRouter(HeaderNavigation);
