import dynamic from 'next/dynamic';
import { useRef, useState, useEffect } from 'react';
import Link from 'next/link';
import axios from 'axios';
import HeaderNavigation from './HeaderNavigation';
import HeaderNavigationMobile from './HeaderNavigationMobile';
import { useLocationProvider } from '../context/location';
import { useObjectsProvider } from '../context/objects';
import { useRouter } from 'next/router';
import { useCookies } from "react-cookie";
import location from '../data/location.json';
import Input from './Input';

import Logo from '../public/images/logo.svg';
import Moved from '../public/images/moved.svg';

import css from '../styles/modules/home/Moved.module.scss'

const Modal = dynamic(() => import('./Modal'));

const Header = (region) => {
  /** STATES */
  const [locationState, setLocationState] = useLocationProvider();
  const [objectsState, setObjectsState] = useObjectsProvider();
  const [cookie, setCookie] = useCookies(["region"]);
  const [isModalLocationVisible, setIsModalLocationVisible] = useState(false);
  const [isModalMovedVisible, setIsModalMovedVisible] = useState(false);
  const [isModalMovedClicked, setIsModalMovedClicked] = useState(false);

  const router = useRouter();

  if (router?.query?.from?.length && !isModalMovedVisible && !isModalMovedClicked)
    setIsModalMovedVisible(true);

  const [searchRegion, setSearchRegion] = useState('');
  const inputId = useRef();

  if (region && region.region)
    region = region.region;

  if (!region || region == undefined || (typeof region === 'object' && region.region == undefined) || region == "")
    region = locationState.code;

  /** HANDLERS */
  const handleLocationClick = (v) => {
    setIsModalLocationVisible(false);
    if (locationState.code === v.code)
      return;

    setLocationState({ type: 'SET_LOCATION', payload: { ...locationState, ...v } });

    const newReference = [];
    newReference.locality = [];

    let currentDate = new Date();
    let cookieDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());

    const fetchData = async () => {
      try {
        const response = await axios.post(process.env.NEXT_PUBLIC_API + '/objects/reference', { region: v.region });

        if (!response.data || response.data.status !== 'ok')
          throw new Error(response.data.text);

        if (response.data.data.location['localityName'].length > 0) {
          response.data.data.location['localityName'].map((l) => (
            newReference.locality.push({ type: 'option', text: l, value: l })
          ));

          setObjectsState({ type: 'SET_REFERENCE', payload: newReference });
        }
      } catch (error) {
      }
    };
    fetchData();
    localStorage.setItem('location', v.code);
    setCookie("region", v.code, {
      path: "/",
      expires: cookieDate
    });

    router.replace('/' + v.code);
  };

  const handleMovedClose = async () => {
    setIsModalMovedClicked(true);
    setIsModalMovedVisible(false);
  }

  return (
    <header>
      <Modal
        title="Мы переехали"
        visibility={isModalMovedVisible}
        onClose={handleMovedClose}
      >
        <div className={css.title}>
          Добро пожаловать!
        </div>
        <div className={css.body}>
          У нас новое имя
        </div>
        <div className={css.site}>
          baza.estate
        </div>
        <div className="logo">
          <Moved />
        </div>
      </Modal>
      <Modal
        title="Выбор локации"
        visibility={isModalLocationVisible}
        onClose={() => setIsModalLocationVisible(false)}
      >
        <div style={{ marginBottom: 18 }}>
          Выберите регион в котором будет осуществляться поиск объектов. Например,
          &quot;Краснодарский край&quot; для поиска в регионе и прилегающих зонах рядом!
        </div>

        <Input
          inputs={[
            {
              placeholder: 'Фильтр: краснодарский край',
              value: searchRegion,
              type: 'text'
            }
          ]}
          isWrappedStyle
          ref={inputId}
          onChange={(v) => setSearchRegion(v[0])}
        />
        <div className="locations">
          {location.filter(el => el.region.toLowerCase().includes(searchRegion.toLowerCase())).map((value, index) => (
            <button
              key={index}
              type="button"
              className={['location', locationState.region === value.region && 'active']
                .filter(Boolean)
                .join(' ')}
              onClick={() => handleLocationClick(value)}
            >
              {value.region}
            </button>
          ))}
        </div>
      </Modal>
      <section className="preheader">
        <div className="inner">
          <button
            className="сity"
            type="button"
            onClick={() => { setIsModalLocationVisible(true); setSearchRegion('') }}
          >
            {locationState.region}
          </button>
        </div>
      </section>
      <section className="header">
        <Link href={['/', region ?? ''].join('')} passHref>
          <div className="logo">
            <Logo />
          </div>
        </Link>
        <HeaderNavigation region={region} />
        <HeaderNavigationMobile region={region} />
      </section>
    </header>
  );
};

export default Header;
