import { useState, useRef, Fragment, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import navigationData from '../data/navigation.json';

const HeaderNavigationMobile = ({ router, region }) => {
  /** STATES */
  const [isVisible, setVisibility] = useState(false);

  /** REFS */
  const parentEl = useRef(null);

  /** HOOKS */
  useEffect(() => {
    if (!isVisible) enableBodyScroll(parentEl.current);
    else disableBodyScroll(parentEl.current);
  }, [isVisible]);

  /** TEMPLATES */
  return (
    <Fragment>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="menu mobile"
          >
            {navigationData.map((value, index) => (
              <Link href={[value.region ? "/" + region : '', value.path].join('')} key={index} passHref>
                <a
                  className="link"
                  role="button"
                  tabIndex={0}
                  onClick={() => setVisibility(false)}
                  title={value.text}
                >
                  {value.text}
                  {value.label && <span className={value.labelColor}>{value.label}</span>}
                </a>
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      <div className="actions" ref={parentEl}>
        <button
          className={['button', isVisible ? 'active' : ''].join(' ')}
          type="button"
          aria-label="Открыть меню"
          onClick={() => setVisibility(!isVisible)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <path
              className="line"
              d="M14 26C14 26 43.5 26 46 26C50 26 54 26 54 34C54 42 53.5417 44.5 49 44.5C45 44.5 44.7762 44.7762 42 42C39 39 18 18 18 18"
            />
            <path
              className="line"
              d="M14 34C14 34 43.5 34 46 34C50 34 54 34 54 26C54 18 53.5417 15.5 49 15.5C45 15.5 44.7762 15.2238 42 18C39 21 18 42 18 42"
            />
          </svg>
        </button>
      </div>
    </Fragment>
  );
};

export default HeaderNavigationMobile;
