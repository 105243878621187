import { memo, useState, useRef, useEffect, forwardRef, useImperativeHandle, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import css from '../styles/modules/Input.module.scss';

const Input = forwardRef(({ title, prefix, suffix, inputs, visibility, isWrappedStyle, style, onChange, onBlur, onFocus , id }, ref) => {
  /** STATES */
  const [isError, setIsError] = useState(false);
  const [values, setValues] = useState(inputs.map((value) => (value && value.value ? value.value.toString() : '')));
  const parentId = useRef();
  const inputId = useRef();

  /** FUNCTIONS */
  const handleOnClick = () => {
    if (inputId.current && isWrappedStyle)
      inputId.current.focus();
  };
  const getInput = (d, i) => {
    if (d.mask === 'number' || d.mask === 'money')
      return (
        <NumberFormat
          thousandSeparator=" "
          value={values[i]}
          placeholder={d.placeholder}
          allowNegative={false}
          isAllowed={({ formattedValue, floatValue }) => formattedValue === '' || floatValue <= (d.max || 9999999999)}
          type={d.type}
          onChange={(e) => {
            const newValues = [...values];
            newValues[i] = Number(e.target.value.replace(/[^0-9.]/g, ''))
              ? e.target.value.replace(/[^0-9.]/g, '')
              : '';

            setValues(newValues);
            setIsError(false);
            if (onChange)
              onChange(newValues);
          }}
          onFocus={() => {
            if (onFocus)
              onFocus(values);
          }}
          onBlur={() => {
            if (onBlur)
              onBlur(values);
          }}
          style={d.style}
          getInputRef={inputId}
        />
      );

    if (d.mask === 'phone')
      return (
        <NumberFormat
          value={values[i].slice(1)}
          className={css.inputZone}
          format="+7 ### ### ## ##"
          type={d.type}
          placeholder={d.placeholder}
          onChange={(e) => {
            const newValues = [...values];
            newValues[i] = e.target.value.replace(/[^0-9.]/g, '');

            setValues(newValues);
            setIsError(false);
            if (onChange)
              onChange(newValues);
          }}
          onFocus={() => {
            if (onFocus)
              onFocus(values);
          }}
          onBlur={() => {
            if (onBlur)
              onBlur(values);
          }}
          style={d.style}
          getInputRef={inputId}
        />
      );

    return (
      <input
        key={i}
        placeholder={d.placeholder}
        value={values[i]}
        data-index={i}
        type={d.type}
        onChange={(e) => {
          const newValues = [...values];
          newValues[i] = e.target.value || '';

          setValues(newValues);
          setIsError(false);
          if (onChange)
            onChange(newValues);
        }}
        onFocus={() => {
          if (onFocus)
            onFocus(values);
        }}
        onBlur={() => {
          if (onBlur)
            onBlur(values);
        }}
        style={d.style}
        ref={inputId}        
        id={id}
      />
    );
  }

  /** HOOKS */
  useEffect(() => {
    setValues(inputs.map((value) => (value && value.value ? value.value.toString() : '')));
  }, [inputs]);
  useImperativeHandle(ref, () => ({
    setError(s) {
      if (!s) {
        setIsError(false);
        return;
      }

      parentId.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      parentId.current.animate(
        [
          { transform: 'translate3d(-2px, 0, 0)' },
          { transform: 'translate3d(2px, 0, 0)' },
          { transform: 'translate3d(-5px, 0, 0)' },
          { transform: 'translate3d(5px, 0, 0)' },
          { transform: 'translate3d(0, 0, 0)' }
        ],
        200
      );

      if (isError)
        return;

      setIsError(true);
      setTimeout(() => setIsError(false), 5000);
    }
  }));

  /** TEMPLATES */
  if (!inputs.length || visibility === false)
    return null;

  return (
    <div
      className={[css.in, isWrappedStyle && css.full, isError && css.error].filter(Boolean).join(' ')}
      style={style}
      onClick={handleOnClick}
      ref={parentId}
    >
      {title && <div className={css.inTitle}>{title}</div>}
      <div className={css.inContent}>
        {prefix && <div className={css.inPrefix}>{prefix}</div>}
        {inputs.map((value, index) => (
          <Fragment key={index}>
            {index > 0 && <div className={css.inDivider} />}
            {getInput(value, index)}
          </Fragment>
        ))}
        {suffix && <div className={css.inSuffix}>{suffix}</div>}
      </div>
    </div>
  );
});

Input.displayName = 'Input';
Input.defaultProps = {
  inputs: [],
  visibility: true
};

export default memo(Input, (p, n) => {
  return JSON.stringify(p.inputs) === JSON.stringify(n.inputs) && p.visibility === n.visibility;
});
